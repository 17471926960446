import React from 'react';

import { Box, Typography } from '@mui/material';

import { useHeadless } from '../../context/headless-provider';

export const selectSubhead = (context) => {
  return context.display.filter((d) => {
    return d.role === 'rich-text/subhead';
  });
};

export const Subhead = () => {
  const subhead = useHeadless(selectSubhead);

  if (subhead.length === 0) {
    return null;
  }

  return (
    <Box>
      {subhead.map(({ props, content }, i) => {
        return (
          <Typography
            key={i}
            component={'span'}
            variant={props?.variant || 'h5'}
            sx={{
              display: 'inline-block',
              ...props?.sx,
            }}
          >
            {content.richText}
          </Typography>
        );
      })}
    </Box>
  );
};
