import React from 'react';

import { Container, Box } from '@mui/material';

import Header from '../../shared/header-without-call-to-action';
import Footer from '../../shared/footer';

import { Headline } from '../../slots/headline';
import { Subhead } from '../../slots/subhead';

const PageContent = ({ data }) => {
  const { kalansoPage } = data;
  return (
    <>
      <Header page={kalansoPage} />

      <Container maxWidth="sm">
        <Box sx={{ textAlign: 'center', my: '2rem', height: "60vh" }}>
          <Headline />
          <Subhead />
        </Box>
      </Container>

      <Footer page={kalansoPage} />
    </>
  );
};

export default PageContent;
