import React from 'react';

import { Box, Typography } from '@mui/material';

import { useHeadless } from '../../context/headless-provider';

export const selectHeadline = (context) => {
  return context.display.filter((d) => {
    return d.role === 'rich-text/headline';
  });
};

export const Headline = () => {
  const headline = useHeadless(selectHeadline);

  return (
    <Box component="h1" sx={{ display: 'flex', flexDirection: 'column' }}>
      {headline.map(({ props, content }, i) => {
        return (
          <Typography
            key={i}
            component={'span'}
            variant={props?.variant || 'h3'}
            align={props?.align || 'center'}
            sx={{
              display: 'inline-block',
              color: `common.black`,
              fontSize: { xs: '1.5rem', sm: '2rem', md: '3rem' },
              fontWeight: 900,
              ...props?.sx,
            }}
          >
            {content.richText}
          </Typography>
        );
      })}
    </Box>
  );
};
