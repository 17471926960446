import React from 'react';

import { graphql } from 'gatsby';

import PageContent from '../components/email-link-sent/variants/owned-traffic';

export const query = graphql`
  query EmailLinkSentOwnedTrafficPageQuery(
    $pageId: String!
    $breakpoints: [Int!]
  ) {
    kalansoPage(pageId: { eq: $pageId }) {
      ...KalansoFullPageFragment
    }
  }
`;

const EmailLinkSentOwnedTrafficTemplate = (props) => {
  return <PageContent {...props} />;
};

export default EmailLinkSentOwnedTrafficTemplate;
